<template>
  <v-container fluid class="pt-0 fill-height">
    <v-row justify="center" style="height: 100%; display: flex; flex-direction: column; flex-wrap: nowrap">
      <v-col cols="12" style="flex: 0">
        <v-card class="pb-3">
          <v-toolbar tile elevation="0" outlined dense style="border: none"
                     :height="$vuetify.breakpoint.xs ? 90 : 54">
            <template v-if="$vuetify.breakpoint.smAndUp">
              <v-toolbar-title class="font-weight-medium" style="font-size: 1.1rem">Реєстр внесених залишків</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items class="shrink">
                <v-row class="align-center">
                  <v-btn fab depressed small class="mr-2" @click.stop="createDocument">
                    <v-icon color="grey darken-2">
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </v-row>
              </v-toolbar-items>
            </template>
            <template v-else>
              <div style="width: 100%">
                <div class="d-flex mb-2">
                  <v-toolbar-title class="font-weight-medium" style="font-size: 1.3rem">
                    Реєстр внесених залишків
                  </v-toolbar-title>
                </div>
                <div class="d-flex">
                      <v-spacer></v-spacer>
                      <v-btn depressed small @click.stop="createDocument" height="36"
                      >
                        <v-icon color="grey darken-2">
                          mdi-plus
                        </v-icon>
                        Створити
                      </v-btn>
                </div>
              </div>
            </template>
          </v-toolbar>
          <v-text-field class="px-4 pt-0" v-model="search"
                        hide-details placeholder="Введіть текст для пошуку"
                        color="grey darken-1" clearable clear-icon="mdi-close"
                        prepend-icon="mdi-text-box-search-outline"
                        @input="searchByText"
          />
        </v-card>
      </v-col>
      <v-col cols="12" style="flex: 1">
        <template v-if="items.length">
          <v-card
              v-for="(item, idx) in filtered_items" :key="idx" tile elevation="2"
              class="mb-2 d-flex flex-wrap flex-row row-card"
              style="border-left: 3px solid #5bb55f"
              @click.stop="openDocument(item.id)"
          >
            <v-col cols="12" md="2" :class="$vuetify.breakpoint.xs ? 'pb-0' : ''">
              <div>Дата створення</div>
              <div>{{ item.create_date | formatDate('DD.MM.YYYY HH:mm:ss') }}</div>
            </v-col>
            <v-col cols="12" md="2" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
              <div>Місяць</div>
              <div>{{ item.month | formatDate }}</div>
            </v-col>
            <v-col cols="12" md="3" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
              <div>Сума / К-сть</div>
              <div>
                <span>{{ item.total_sum | formatToFixed }}</span> / <span>{{ item.total_row }}</span></div>
            </v-col>
            <v-col cols="12" md="2" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
              <div>Автор</div>
              <div>{{ item.user_name }}</div>
            </v-col>
            <v-col cols="12" md="3" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''">
              <div>Коментар</div>
              <div style="font-style: oblique">{{ item.comment ? item.comment : 'Коментар відсутній' }}</div>
            </v-col>
          </v-card>
        </template>
        <template v-else>
          <v-card style="height: 100%; display: flex; align-items: center; justify-content: center">
            <div class="wrapper align-center text-center">
              <v-icon size="200" color="success" style="opacity: .24">mdi-clock</v-icon>
              <div class="title grey--text text--darken-2 px-4">Документи для відображення відсутні <br> Спершу створіть документ</div>
            </div>
          </v-card>
        </template>
        <template>
          <div class="pay-table-pagination d-flex justify-start" v-if="items.length">
            <div class="pa-3 d-flex align-center justify-start">
              <v-btn text depressed icon class="mr-1"
                     @click.stop="pageStartOrEnd(true)"
                     :disabled="items_per_page > items.length || page === 1"
              >
                <v-icon>mdi-chevron-double-left</v-icon>
              </v-btn>
              <v-btn text depressed icon
                     @click.stop="changePage(true)"
                     :disabled="page === 1"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <div class="mx-2">
                <v-chip>
                  {{ `Сторінка: ${page} із ${pages}` }}
                </v-chip>
              </div>
              <v-menu top :close-on-click="true" :close-on-content-click="true">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip v-bind="attrs" v-on="on" class="mr-2">
                    {{ `${items_per_page} на стор.` }}
                  </v-chip>
                </template>
                <v-list>
                  <v-list-item
                      v-for="(item, index) in items_per_page_list"
                      :key="index"
                      @click="setItemsPerPage(item)"
                  >
                    <v-list-item-title>{{ item }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn text depressed icon class="mr-1"
                     @click.stop="changePage(false)"
                     :disabled="items_per_page > items.length || page === pages"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
              <v-btn text depressed icon
                     @click.stop="pageStartOrEnd(false)"
                     :disabled="page === pages"
              >
                <v-icon>mdi-chevron-double-right</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import oddmentsAPI from '@/utils/axios/oddments'
import {ALERT_SHOW} from "@/store/actions/alert";
import {mapGetters} from "vuex";

export default {
  name: "OddmentsList",
  components: {
  },
  computed: {
    ...mapGetters({
      legacy: 'isLegacy'
    }),
    filtered_items() {
      return this.items.slice((this.page - 1) * this.items_per_page, (this.page - 1) * this.items_per_page + this.items_per_page)
    }
  },
  data() {
    return {
      search: '',
      items: [],
      pages: 0,
      items_per_page: 9,
      page: 0,
      items_per_page_list: [9, 12, 14, 16, 18, 20, 25, 50],
    }
  },
  methods: {
    searchByText(e) {
      if (e && e.length > 1) {
        this.fetchHeaders(e)
      }

      if (!e) {
        this.fetchHeaders(null)
      }
    },
    changePage(left) {
      if (left) {
        this.page = this.page === 1 ? 1 : this.page - 1
      } else {
        this.page = this.page === this.pages ? this.pages : this.page + 1
      }
    },
    setPageSplitter() {
      this.pages = Math.ceil(this.items.length / this.items_per_page)
      this.page = this.pages ? 1 : 0

      if (this.pages * this.items_per_page - this.items_per_page > this.items.length) {
        this.pages -= 1
      }
    },
    setItemsPerPage(per_page) {
      this.items_per_page = per_page
      this.setPageSplitter()
    },
    pageStartOrEnd(start) {
      if (start) {
        this.page = 1
      } else {
        this.page = this.pages
      }
    },
    onMonthChange(payload) {
      if (payload) {
        this.fetchHeaders()
      }
    },
    fetchHeaders(text=null) {
      const payload = {
        month: this.current_month,
        legacy: this.legacy
      }
      if (text) {
        payload.text = text
      }
      oddmentsAPI.get_headers(payload)
        .then(response => response.data)
        .then(data => {
          this.items = data
          this.setPageSplitter()
        })
        .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
    },
    openDocument(payload) {
      if (this.legacy) {
        this.$router.push({ name: 'OddmentsHeaderLegacy', params: { header_id: payload } })
      } else {
        this.$router.push({ name: 'OddmentsHeader', params: { header_id: payload } })
      }
    },
    createDocument() {
      if (this.legacy) {
        this.$router.push({ name: 'OddmentsHeaderLegacy', params: { header_id: 'create' } })
      } else {
        this.$router.push({ name: 'OddmentsHeader', params: { header_id: 'create' } })
      }
    }
  },
  created() {
    this.fetchHeaders()
  }
}
</script>

<style scoped lang="scss">
  .row-card {
    div > div:nth-child(1) {
      font-size: .68rem;
      font-weight: 400;
      color: #757575
    }
    div > div:nth-child(2) {
      font-size: .84rem;
      color: #4a4a4a;

      span:nth-child(1) {
        font-weight: 500;
        color: #2a9d2f;
      }
      span:nth-child(2) {
        font-weight: 500;
        color: #2d2d2d;
      }
    }
  }
</style>